import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "use-a-chatbot-when-a-user-would-benefit-from-requesting-information-on-demand-with-natural-language-they-should-receive-a-useful-and-trustworthy-response-related-to-their-question-or-task"
    }}>{`Use a chatbot when a user would benefit from requesting information on-demand with natural language. They should receive a useful and trustworthy response related to their question or task.`}</h3>
    <h4 {...{
      "id": "status"
    }}>{`Status:`}</h4>
    <p>{`Experimental`}</p>
    <h4 {...{
      "id": "maintainer"
    }}>{`Maintainer:`}</h4>
    <p><a parentName="p" {...{
        "href": "https://github.com/vincentsnagg"
      }}>{`Vincent Snagg`}</a></p>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Common use cases</AnchorLink>
      <AnchorLink mdxType="AnchorLink">User needs</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Why a chatbot?</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Supported components</AnchorLink>
    </AnchorLinks>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "62.05357142857143%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Requesting information from a chatbot",
        "title": "Requesting information from a chatbot",
        "src": "/static/3a6170b12ec31de8bec94cd1981b8e2c/fb070/chatbot-0.png",
        "srcSet": ["/static/3a6170b12ec31de8bec94cd1981b8e2c/d6747/chatbot-0.png 288w", "/static/3a6170b12ec31de8bec94cd1981b8e2c/09548/chatbot-0.png 576w", "/static/3a6170b12ec31de8bec94cd1981b8e2c/fb070/chatbot-0.png 1152w", "/static/3a6170b12ec31de8bec94cd1981b8e2c/fb104/chatbot-0.png 1728w", "/static/3a6170b12ec31de8bec94cd1981b8e2c/8fefe/chatbot-0.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "common-use-cases"
    }}>{`Common use cases`}</h2>
    <p>{`Bots have become an increasingly popular way to solve common business problems across domains, including:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Providing in-context help and guidance`}</strong>{` (e.g., scaling a company’s FAQ or helping a user get started)`}</li>
      <li parentName="ul"><strong parentName="li">{`Triaging or setting priority of cases`}</strong>{` by soliciting information from the user to decrease workload on humans (support tickets, patient symptom severity)`}</li>
      <li parentName="ul"><strong parentName="li">{`Performing common tasks or procedures`}</strong>{` (e.g., pay a bill, transfer money, or assist with electing health benefits)`}</li>
      <li parentName="ul"><strong parentName="li">{`Using natural language to search for information`}</strong>{` (e.g., find specific information like current balance, or dosing instructions for a medication)`}</li>
    </ul>
    <h2 {...{
      "id": "why-a-chatbot"
    }}>{`Why a chatbot?`}</h2>
    <p>{`Before building a bot, make sure that you have justified it as an appropriate solution by asking yourself these questions:`}</p>
    <ul>
      <li parentName="ul">{`What are the users’ goals?`}</li>
      <li parentName="ul">{`How in-depth is the assistance the user will need?`}</li>
      <li parentName="ul">{`Is your domain better served by human assistance?`}</li>
      <li parentName="ul">{`How is a bot superior to online documentation, contextual support, wizards, etc.?`}</li>
      <li parentName="ul">{`What kind of data sources or abilities can this bot leverage for your business and your users?`}</li>
    </ul>
    <h2 {...{
      "id": "user-needs"
    }}>{`User needs`}</h2>
    <h4 {...{
      "id": "a-user-may-need"
    }}>{`A user may need:`}</h4>
    <ul>
      <li parentName="ul">{`Access to help at any time`}</li>
      <li parentName="ul">{`An on-demand, relevant answer to a question`}</li>
      <li parentName="ul">{`High quality, consistent information about a product or service`}</li>
    </ul>
    <h4 {...{
      "id": "within-a-conversation-a-user-may-need"
    }}>{`Within a conversation, a user may need:`}</h4>
    <ul>
      <li parentName="ul">{`To provide the bot with more context via structured responses`}</li>
      <li parentName="ul">{`To know what the bot understands, through reflection and confirming questions`}</li>
      <li parentName="ul">{`To identify the most recent and relevant message to improve understanding`}</li>
    </ul>
    <h4 {...{
      "id": "dont-use-a-chatbot-when"
    }}>{`Don’t use a chatbot when:`}</h4>
    <ul>
      <li parentName="ul">{`A task could be accomplished more efficiently using a traditional user interface`}</li>
      <li parentName="ul">{`A process is very complex or could take a long time`}</li>
      <li parentName="ul">{`A real human is needed for sensitive or emotional topics`}</li>
    </ul>
    <p>{`See `}<a parentName="p" {...{
        "href": "https://www.ibm.com/design/ai/conversation"
      }}>{`IBM Design for Conversational AI`}</a>{` for more information.`}</p>
    <h2 {...{
      "id": "supported-components"
    }}>{`Supported components`}</h2>
    <p>{`While conversational interfaces may contain many different kinds of components, Carbon currently offers these components out of the box to help get you up and running quickly. See the Usage tab for information on how to use these components together.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Component`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Status`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chatbot window`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Design available`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chatbot header`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Design available`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`System message`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Design available`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`User message`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Design available`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Structured response`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Design available`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chatbot cards`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Design available`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chatbot text input`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Design available`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "chatbot-add-on-library"
    }}>{`Chatbot add-on library`}</h3>
    <Row className="tile--resource--no-margin tile--group" mdxType="Row">
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Chatbot Sketch library" href="https://ibm.box.com/s/7vauj3l8o88wukv6p80lddwhjlonoipd" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sketch icon",
              "title": "sketch icon",
              "src": "/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png",
              "srcSet": ["/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      